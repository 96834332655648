import request from '@/util/request';

export function getInfo() {
  return request({
    url: `/tran/tranCopyrightRegistration/info`,
    method: 'get'
  });
}

// 免登录 获取详情
export function registrationInfo(id, params) {
  return request({
    url: `/tran/tranCopyrightRegistration/ua/${id}`,
    method: 'get',
    params,
  });
}

// 免登录 获取列表
export function registrationList(params) {
  return request({
    url: `/tran/tranCopyrightRegistration/ua/list`,
    method: 'get',
    params: params
  });
}

// 我的获取列表
export function myRegistrationList(params) {
  return request({
    url: `/tran/tranCopyrightRegistration/list`,
    method: 'get',
    params: params
  });
}

export function postTranCopyrightRegistration(data) {
  return request({
    url: `/tran/tranCopyrightRegistration`,
    method: 'post',
    data
  });
}

export function putTranCopyrightRegistration(data) {
  return request({
    url: `/tran/tranCopyrightRegistration`,
    method: 'put',
    data
  });
}

// 删除
export function deleteTranCopyrightRegistration(ids) {
  return request({
    url: `/tran/tranCopyrightRegistration/${ids}`,
    method: 'delete'
  });
}
// 上架
export function publicCopyright(id) {
  return request({
    url: `/tran/tranCopyrightRegistration/${id}/public`,
    method: 'put'
  });
}
// 下架
export function unpublicCopyright(id) {
  return request({
    url: `/tran/tranCopyrightRegistration/${id}/unPublic`,
    method: 'put'
  });
}

// 获取详情
export function registrationDetailInfo(id) {
  return request({
    url: `/tran/tranCopyrightRegistration/${id}/detail`,
    method: 'get'
  });
}