<template>
  <div
    class="width-100 bg-white zIndex flex-row justify-content-center align-items-center"
    style="position: sticky; top: 0; border-bottom: 1px solid #eeeeee"
  >
    <div id="top" class="container-box">
      <div class="width-100 padding-Tb-20 flex-row justify-content-spaceBetween align-items-center">
        <img src="../assets/image/logo.png" @click="$router.push('/')" class="height-logo" alt="" />
        <div class="flex-row justify-content-end align-items-center">
          <div class="list-item margin-ts pointer" v-for="(item, index) in list" :key="index" @click="jumpTo(item, index)">
            <p class="fs-mid fw-mid" :class="active === index ? 'theme-blue' : 'black'">{{ item.text }}</p>
            <p class="list-line" :class="active === index ? 'opacity-1 fadeIn' : 'opacity-0'"></p>
          </div>
          <el-popover class="login-info" v-if="ifLoginIn" placement="bottom-end" width="250" trigger="hover">
            <div class="width-100 margin-b margin-l flex-row justify-content-start align-items-center">
              <el-image v-if="info?.data?.avatarUrl" class="avatar-picture" :src="info.data.avatarUrl" fit="cover"></el-image>
              <div v-else class="avatar-picture white flex-row justify-content-center align-items-center">{{ info?.data?.name }}</div>
              <div class="margin-l">
                <p class="fs-big black fw-mid">{{ info?.data?.name ? info?.data?.name : '-' }}</p>
                <p class="fs-mid darkgrey opacity-8">
                  {{ info?.data?.tranCopyrightRegistrationCount ? info?.data?.tranCopyrightRegistrationCount : 0 }}个版权内容
                </p>
              </div>
            </div>
            <div class="width-100 flex-row justify-content-around align-items-center">
              <div class="textAlign-c pointer margin-ts" @click="$router.push('/personalCenter')">
                <img src="../assets/image/f1.png" width="40%" alt="" />
                <p class="fs-mid darkgrey margin-ts opacity-5">个人中心</p>
              </div>

              <div class="divider-v"></div>
              <div class="textAlign-c pointer margin-ts" @click="loginOut">
                <img src="../assets/image/f2.png" width="40%" alt="" />
                <p class="fs-mid darkgrey margin-ts opacity-5">退出登录</p>
              </div>
            </div>
            <el-button slot="reference"
              ><div class="flex-row justify-content-center align-items-center margin-l">
                <el-image v-if="info?.data?.avatarUrl" class="avatar-picture" :src="info.data.avatarUrl" fit="cover"></el-image>
                <div v-else class="avatar-picture white flex-row justify-content-center align-items-center">{{ info?.data?.name }}</div>
                <i class="el-icon-arrow-down grey fs-big margin-l"></i></div
            ></el-button>
          </el-popover>
          <el-button v-else type="primary" round @click="dialogVisible = true">登录/注册</el-button>
          <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose" :append-to-body="true">
            <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
              <div class="left width-50">
                <img src="../assets/image/a3.jpg" width="100%" alt="" />
              </div>
              <div class="right width-50 flex-column justify-content-start align-items-center">
                <p class="">
                  ————
                  <span class="fs-super-big black fw-mid position-relative margin-l margin-r" style="display: inline-block; top: 5px">欢迎登录</span>
                  ————
                </p>
                <p class="darkgrey line-height2 opacity-8">瑞安版权交易服务平台</p>
                <el-form class="login width-70" ref="form" :model="form">
                  <div v-if="loginType == 1">
                    <el-form-item label="">
                      <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                        <div @click="changeLogin">企业登录></div>
                      </div>
                    </el-form-item>
                    <el-form-item label="">
                      <div class="form-item-box width-100 flex-row justify-content-start align-items-center">
                        <img src="../assets/image/a1.png" class="small-icon" alt="" />
                        <el-input placeholder="请输入手机号" v-model="form.mobile"> </el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="">
                      <div class="form-item-box width-100 flex-row justify-content-start align-items-center">
                        <img src="../assets/image/a2.png" class="small-icon" alt="" />
                        <el-input class="width-60" placeholder="请输入验证码" v-model="form.code"> </el-input>
                        <p class="theme-blue width-40 textAlign-c" @click="getCode()">{{ num ? `${num}s` : '获取验证码' }}</p>
                        <!-- <p class="theme-blue width-50 textAlign-c">获取验证码</p> -->
                      </div>
                    </el-form-item>
                  </div>
                  <div v-if="loginType == 2">
                    <el-form-item label="">
                      <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                        <div @click="changeLogin">个人登录></div>
                        <div @click="gotoCompanyRegi">企业注册</div>
                      </div>
                    </el-form-item>
                    <el-form-item label="">
                      <div class="form-item-box width-100 flex-row justify-content-start align-items-center">
                        <img src="../assets/image/a1.png" class="small-icon" alt="" />
                        <el-input placeholder="请输入统一社会信用代码" v-model="form.companyCode"> </el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="">
                      <div class="form-item-box width-100 flex-row justify-content-start align-items-center">
                        <img src="../assets/image/a1.png" class="small-icon" alt="" />
                        <el-input placeholder="请输入手机号" v-model="form.mobile"> </el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="">
                      <div class="form-item-box width-100 flex-row justify-content-start align-items-center">
                        <img src="../assets/image/a2.png" class="small-icon" alt="" />
                        <el-input class="width-60" placeholder="请输入验证码" v-model="form.code"> </el-input>
                        <p class="theme-blue width-40 textAlign-c" @click="getCode()">{{ num ? `${num}s` : '获取验证码' }}</p>
                        <!-- <p class="theme-blue width-50 textAlign-c">获取验证码</p> -->
                      </div>
                    </el-form-item>
                  </div>
                  <el-form-item label="">
                    <el-checkbox @click="checked = !checked" v-model="checked" class="margin-r"></el-checkbox>
                    <span class="darkgrey opacity-5" style="line-height: 1.5rem">
                      我已阅读并同意<a
                        href="https://www.rabqfw.com/web/%E7%91%9E%E5%AE%89%E7%89%88%E6%9D%83%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.html"
                        target="_blank"
                        >《瑞安版权交易服务平台服务协议》</a
                      >和
                      <a
                        href="https://www.rabqfw.com/web/%E7%91%9E%E5%AE%89%E7%89%88%E6%9D%83%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.html"
                        target="_blank"
                        >《瑞安版权交易服务平台隐私服务协议》</a
                      >
                    </span>
                  </el-form-item>
                  <el-form-item>
                    <el-button class="width-100 login-btn" type="primary" round @click="onSubmit"><span class="fs-big">立即登录</span></el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSms, smsLogin } from '@/api/pcLoginRecord';
import { getInfo } from '@/api/tranCopyrightRegistration';
import { companyLogin, getCompanyMobileCode } from '@/api/tranCompanyUserInfo';

export default {
  name: 'index',
  components: {},
  props: {
    active: Number,
    dialogVisible2: Boolean
  },
  data() {
    return {
      ifLoginIn: !!localStorage.getItem('bqsb_login'),
      info: {},
      loginType: 1,
      // activeNum:this.active,
      dialogVisible: false,
      list: [
        {
          text: '首页',
          router: '/'
        },
        {
          text: '版权展示',
          router: '/copyrightDisplay'
        },
        {
          text: '版权登记',
          router: 'https://bqsb.rarb.cn/web/#/'
        },
        {
          text: '版权发布',
          router: '/copyrightRegistration'
        },
        {
          text: '资讯公告',
          router: '/information'
        },
        {
          text: '在线学习',
          router: '/onlineLearning'
        }
      ],
      form: {
        companyCode: '',
        mobile: '',
        code: ''
      },
      num: 0,
      checked: false
    };
  },
  watch: {
    dialogVisible2(newValue) {
      console.log('newValue2:', newValue);
      if (!localStorage.getItem('bqsb_login')) {
        this.dialogVisible = newValue;
      }
    },
    dialogVisible(newValue) {
      console.log('newValue:', newValue);
      this.dialogVisible2 = newValue;
    }
  },
  async created() {
    if (localStorage.getItem('bqsb_login')) {
      await this.setInfo();
    }
    setInterval(() => {
      if (this.num) this.num--;
    }, 1000);
  },
  methods: {
    jumpTo(item, index) {
      if (item.text === '版权发布') {
        if (localStorage.getItem('bqsb_login')) {
          this.$router.push(item.router);
          this.active = index;
        } else {
          this.dialogVisible = true;
        }
      } else if (item.text === '版权登记') {
        let url = item.router;
        
        if (localStorage.getItem('userType') != '2' && localStorage.getItem('bqsb_login')) url += `?infoMsg=${localStorage.getItem('bqsb_login')}`;
        window.open(url, '_blank');
        // window.location.href = item.router;
      } else {
        this.$router.push(item.router);
        this.active = index;
      }
    },
    handleClose(done) {
      this.dialogVisible = false;
      // this.$confirm('确认关闭？');
      console.log(done);
      // .then((_) => {
      //   done();
      // })
      // .catch((_) => {});
    },
    async getCode() {
      if (!this.checked) {
        this.$alert('请勾选服务协议！', '提示', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return;
      }
      if (this.form.mobile.length === 0) return;
      if (this.num > 0) return;
      this.num = 60;
      if (this.loginType === 1){
        await getSms(this.form.mobile);
      }else {
        await getCompanyMobileCode(this.form.mobile);
      }
    },
    async onSubmit() {
      if (!this.checked) {
        this.$alert('请勾选服务协议！', '提示', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return;
      }
      if (this.loginType === 2 && (!this.form.companyCode || this.form.companyCode.length < 5)) {
        this.$alert('请输入统一社会信用代码！', '提示', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return;
      }
      if (!this.form.mobile || this.form.mobile.length < 4) {
        this.$alert('请输入正确的手机号，并获取验证码！', '提示', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return;
      }
      if (!this.form.code || this.form.code.length != 6) {
        this.$alert('请输入正确的验证码！', '提示', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return;
      }
      console.log(this.loginType);
      if (this.loginType === 1) {
        await this.login();
        if (localStorage.getItem('bqsb_login')) {
          await this.setInfo();
        }
        await this.setInfo();
      }else {
        await this.companyLogin();
        if (localStorage.getItem('bqsb_login')) {
          await this.setInfo();
        }
      }
    },
    async login() {
      const res = await smsLogin(this.form.mobile, this.form.code);
      const reg = /[\u3400-\u9fa5]/;
      if (res.code !== 200) {
        this.$message.error(res.msg);
      } else if (reg.test(res.token)) {
        this.$message.error('token错误');
      } else if (res.msg === '操作成功') {
        // localStorage.setItem('bqsb_login', res.msg);
          localStorage.setItem('userType', '1');
        if (res.token) localStorage.setItem('bqsb_login', res.token);
        // this.$router.replace({ path: '/' });
        location.reload();
      }
    },
    async companyLogin() {
      const res = await companyLogin({
        cardNo: this.form.companyCode,
        mobile: this.form.mobile,
        code: this.form.code
      });
      const reg = /[\u3400-\u9fa5]/;
      if (res.code !== 200) {
        this.$message.error(res.msg);
      } else if (reg.test(res.token)) {
        this.$message.error('token错误');
      } else if (res.msg === '操作成功') {
        // localStorage.setItem('bqsb_login', res.msg);
        if (res.token) localStorage.setItem('bqsb_login', res.token);
        if (res.companyUserInfo) {
          localStorage.setItem('userType', '2');
          localStorage.setItem('audit', res.companyUserInfo.audit);
        }
        // this.$router.replace({ path: '/' });
        location.reload();
      }
    },
    async setInfo() {
      const info = await getInfo();
      this.ifLoginIn = true;
      this.info = info;
      if (info.data.userType == 2) {
          localStorage.setItem('userType', '2');
          localStorage.setItem('audit', info.data.audit);
      }
      console.log('info:', info);
    },
    loginOut() {
      localStorage.removeItem('bqsb_login');
      this.ifLoginIn = false;
    },
    changeLogin() {
      if (this.loginType === 2) {
        this.loginType = 1;
      } else {
        this.loginType = 2;
      }
    },
    gotoCompanyRegi() {
      this.$router.push('/companyRegi');
    }
  }
};
</script>

<style lang="less" scoped>
.list-item {
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .list-line {
    width: 30px;
    height: 4px;
    margin-top: 3px;
    border-radius: 5px;
    background: #007aff;
  }
}
.login {
  margin-top: 60px;
  .small-icon {
    width: 20px;
  }
  .form-item-box {
    border-radius: 50px;
    border: 1px solid #cccccc;
    padding: 5px 30px;
  }
  .login-btn {
    border-radius: 50px;
    background: #1560f3;
    margin-top: 10px;
  }
}
.avatar-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #4199ff;
  white-space: pre-wrap;
}
</style>
<style>
.el-dialog__header,
.el-dialog__body {
  padding: 0;
}
.el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
.login .el-input__inner::placeholder {
  color: #999999;
  font-size: 18px;
}
.login .el-input__inner {
  font-size: 18px;
  color: #333333;
}
.login .el-checkbox {
  line-height: 1.5rem;
}
.login .el-checkbox__inner {
  width: 1.2rem !important;
  height: 1.2rem !important;
}
.login .el-checkbox__inner::after {
  width: 0.3rem !important;
  border-width: 0.15rem;
  top: 0.2rem !important;
}
.el-input__inner {
  border: none !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #1560f3;
  border-color: #1560f3;
}
.el-form-item__content {
  line-height: 1.5rem;
}
.login-info .el-button,
.login-info .el-button:hover {
  background: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0 0 0 20px;
}
</style>
