import request from '@/util/request';

export function getInfo() {
  return request({
    url: `/bqsb/companyUserInfo/info`,
    method: 'get'
  });
}

// 免登录 获取详情
export function registrationInfo(id, params) {
  return request({
    url: `/bqsb/companyUserInfo/ua/${id}`,
    method: 'get',
    params,
  });
}

// 免登录 获取列表
export function registrationList(params) {
  return request({
    url: `/bqsb/companyUserInfo/ua/list`,
    method: 'get',
    params: params
  });
}

// 我的获取列表
export function myRegistrationList(params) {
  return request({
    url: `/bqsb/companyUserInfo/list`,
    method: 'get',
    params: params
  });
}

export function postCompanyUserInfo(data) {
  return request({
    url: `/bqsb/companyUserInfo/web/register`,
    method: 'post',
    data
  });
}

export function putCompanyUserInfo(data) {
  return request({
    url: `/bqsb/companyUserInfo`,
    method: 'put',
    data
  });
}

// 删除
export function deleteCompanyUserInfo(ids) {
  return request({
    url: `/bqsb/companyUserInfo/${ids}`,
    method: 'delete'
  });
}

// 上架
export function publicCopyright(id) {
  return request({
    url: `/bqsb/companyUserInfo/${id}/public`,
    method: 'put'
  });
}

// 下架
export function unpublicCopyright(id) {
  return request({
    url: `/bqsb/companyUserInfo/${id}/unPublic`,
    method: 'put'
  });
}

// 获取详情
export function registrationDetailInfo(id) {
  return request({
    url: `/bqsb/companyUserInfo/${id}/detail`,
    method: 'get'
  });
}

// 企业登录
export function companyLogin(data) {
  return request({
    url: `/bqsb/companyUserInfo/login/webCompany`,
    method: 'post',
    data
  });
}

// 获取验证码
export function getCompanyMobileCode(mobile) {
  return request({
    url: `/bqsb/companyUserInfo/mobile/${mobile}/code`,
    method: 'get'
  });
}